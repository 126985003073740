import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible/index.js";
import "./assets/css/index.less"
import './utils/pageShow'

import moment from 'moment'
import http from './utils/http'
import baseURL from './utils/baseURL/baseURL'
import utils from './utils/utils'
import  * as filter from  './filter/filter'

import {
  Button,
  Divider,
  NavBar,
  Icon,
  Popup,
  Cell,
  CellGroup,
  Swipe,
  SwipeItem,
  Search,
  Tabbar, 
  TabbarItem,
  Collapse, 
  CollapseItem,
  Empty,
  Form,
  Field,
  Dialog,
  Loading,
  RadioGroup,
  Radio,
  Uploader,
  Tab,
  Tabs,
  Checkbox,
  CheckboxGroup,
  PullRefresh,
  Sticky,
  Skeleton,
  ActionSheet,
  DatetimePicker,
  ShareSheet,
  Tag,
  NoticeBar,
  Popover,
  DropdownMenu,
  DropdownItem,
  List,
  Overlay,
  Area,
  CountDown
} from 'vant';

import { Input, radio, radioGroup, checkbox, checkboxButton, checkboxGroup, form, formItem, datePicker } from 'element-ui';
Vue.use(Input).use(radio).use(radioGroup).use(checkbox).use(checkboxButton).use(checkboxGroup).use(form).use(formItem).use(datePicker);
Vue.use(CountDown)
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Divider)
Vue.use(Empty)
Vue.use(Form)
Vue.use(Field)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Uploader)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(PullRefresh)
Vue.use(Sticky)
Vue.use(Skeleton)
Vue.use(ActionSheet)
Vue.use(DatetimePicker)
Vue.use(ShareSheet)
Vue.use(Tag)
Vue.use(NoticeBar)
Vue.use(Popover)
Vue.use(DropdownMenu).use(DropdownItem).use(List).use(Overlay).use(Area)

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$baseURL = baseURL
Vue.prototype.$utils = utils;
Vue.prototype.$moment = moment;
Object.keys(filter).forEach(k => Vue.filter(k, filter[k]));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')