const base64 = require('js-base64')
import VueCookies from 'vue-cookies'
let Utils = {
    storage: window.localStorage,
    sessionStorage: window.sessionStorage,
    setStorage: function (key, value) {
        this.storage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value))
    },
    getStorage: function (key) {
        let result = this.storage.getItem(key)
        try {
            return JSON.parse(result)
        } catch (e) {
            return result
        }
    },
    removeStorage: function (key) {
        this.storage.removeItem(key)
    },
    clearStorage: function () {
        this.storage.clear()
    },
    setSessionStorage: function (key, value) {
        this.sessionStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value))
    },
    getSessionStorage: function (key) {
        let result = this.sessionStorage.getItem(key)
        try {
            return JSON.parse(result)
        } catch (e) {
            return result
        }
    },
    clearSessionStorage: function () {
        this.sessionStorage.clear()
    },
    getUserStorage: function () {
        return this.getStorage('user');
    },
    getUserIdStorage: function () {
        return this.getStorage('userid');
    },
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function (e) {
            if (callback) {
                callback(this.result);
            }
        }
    },
    getWeiXinUrl() {
        let url = ''
        let userAgent = navigator.userAgent;
        if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
            url = window.entryUrl;
        } else {
            url = window.location.href;
        }
        return url;
    },
    identityCodeValid(idcode) {
        // 加权因子
        var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验码
        var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

        var code = idcode + "";
        var last = idcode[17]; //最后一位

        var seventeen = code.substring(0, 17);

        // ISO 7064:1983.MOD 11-2
        // 判断最后一位校验码是否正确
        var arr = seventeen.split("");
        var len = arr.length;
        var num = 0;
        for (var i = 0; i < len; i++) {
            num = num + arr[i] * weight_factor[i];
        }

        // 获取余数
        var resisue = num % 11;
        var last_no = check_code[resisue];

        // 格式的正则
        // 正则思路
        /*
        第一位不可能是0
        第二位到第六位可以是0-9
        第七位到第十位是年份，所以七八位为19或者20
        十一位和十二位是月份，这两位是01-12之间的数值
        十三位和十四位是日期，是从01-31之间的数值
        十五，十六，十七都是数字0-9
        十八位可能是数字0-9，也可能是X
        */
        var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

        // 判断格式是否正确
        var format = idcard_patter.test(idcode);

        // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
        return last === last_no && format ? true : false;
    },
    handleLargerImg(re, rp1, rp2, str) {
        var arr = str.match(re)
        var ret = ''
        var rpt = ''
        var re2 = null
        if (!arr) return str
        for (var i = 0; i < arr.length; i++) {
            ret = arr[i]
            rpt = rp2 + arr[i] + rp1
            re2 = new RegExp(ret, 'ig')
            str = str.replace(re2, rpt)
        }
        return str
    },
    encodePhone(str) {
        return base64.encode(this.encode(str))
    },
    decodePhone(str) {
        return this.decode(base64.decode(str))
    },
    encodeGroup(str) {
        return base64.encode(str)
    },
    decodeGroup(str) {
        return base64.decode(str)
    },
    handlePhone(str) {
        var char  = ''
        var phone = ''
        for (var i = 1; i < str.length; i += 2) [
            char += str[i]
        ]
        for(var j = 0; j < char.length; j ++) {
            phone += (char[j].charCodeAt() - 97) + ''
        }
        return phone
    },
    encode(str) { //加密
        var encode = "";
        str = String(str);
        for (var i = 0; i < str.length; i++) {
          var code = str.charCodeAt(i);
          encode += code;
        }
        return encode
    },
    decode(str) {//解密
        var arr = [], decode = "";
        for (var i = 0; i < str.length; i +=2) {
            arr.push(str.substr(i, 2))
        }
        for (var i = 0; i < arr.length; i++){
            var code = parseInt(arr[i]);
            decode += String.fromCharCode(code);
        }
        return decode;
    },
    setCookie(key, value, time) {
        if (time) {
            VueCookies.set(key, value, time)
        } else {
            VueCookies.set(key, value)
        }
    },
    getCookie(key) {
        return VueCookies.get(key);
    },
    removeCookie(key) {
        VueCookies.remove(key);
    },
    uniqueArray(arr) {
        var arr1 = [] // 新建一个数组来存放arr中的值
        for(var i=0,len=arr.length;i<len;i++){
            if(arr1.indexOf(arr[i]) === -1){
                arr1.push(arr[i])
            }
        }
        return arr1
    }
};




export default Utils