let subRouter = [
  {
    path: "/",
    name: "index",
    component: () => import("@/layouts/basicLayout"),
    redirect: "/index",
    meta: { title: "首页", icon: "flag", theme: "filled", keepAlive: true },
    children: [
      {
        path: "/index/:miceLink",
        name: "home",
        component: () => import("@/views/index/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/index1/:miceLink",
        name: "home1",
        component: () => import("@/views/index/index1"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/index2/:miceLink",
        name: "home2",
        component: () => import("@/views/index/index2"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/index3/:miceLink",
        name: "home3",
        component: () => import("@/views/index/index3"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/user/:miceLink",
        name: "user",
        component: () => import("@/views/user/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/selfpage/:linkname/:miceLink",
        name: "selfpage",
        component: () => import("@/views/selfPage/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/schedule/:miceLink/:id/:name",
        name: "schedule",
        component: () => import("@/views/schedule/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: false }
      },
      {
        path: "/journey/:miceLink/:isEnter",
        name: "journeyEnter",
        component: () => import("@/views/journey/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/enterprise/:miceLink?",
        name: "enterprise",
        component: () => import("@/views/enterprise/index"),
        meta: { title: "", icon: "", theme: "filled" }
      },
      {
        path: "/arrangeDay/:miceLink",
        name: "arrangeDay",
        component: () => import("@/views/arrangeDay/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
      {
        path: "/map/:miceLink",
        name: "map",
        component: () => import("@/views/map/index"),
        meta: { title: "", icon: "", theme: "filled", keepAlive: true }
      },
    ]
  },
  {
    path: '/index/userList/:miceLink',
    name: 'userList',
    component: () => import('@/views/index/userList'),
    meta: { title: '', icon: '', theme: 'filled', keepAlive: true }
  },
  {
    path: "/hospital/about/:miceLink",
    name: "hospitalAbout",
    component: () => import("@/views/hospital/index"),
    meta: {title: "", icon: "", theme: "filled"}
  },
  {
    path: "/signup/:miceLink/:sharer?",
    name: "signup",
    component: () => import("@/views/signup/template"),
    meta: { title: "注册报名", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/journey/:miceLink",
    name: "journey",
    component: () => import("@/views/journey/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "*",
    name: "no-exist",
    component: () => import("@/components/noPage/noPage"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/traffic/:miceLink",
    name: "traffic",
    component: () => import("@/views/traffic/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/micevideo/:miceLink/:videoId?",
    name: "micevideo",
    component: () => import("@/views/video/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/news/:miceLink",
    name: "news",
    component: () => import("@/views/newsMore/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/reception/:miceLink",
    name: "reception",
    component: () => import("@/views/reception/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/historyMeeting/:miceLink",
    name: "historyMeeting",
    component: () => import("@/views/historyMeeting/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/conference/:miceLink/:groupLink",
    name: "conference",
    component: () => import("@/views/conferencePage/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/liveTv/:miceLink",
    name: "liveTv",
    component: () => import("@/views/liveTv/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/newspage/:id",
    name: "newsPage",
    component: () => import("@/views/newsPage/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/seats/:miceLink",
    name: "seats",
    component: () => import("@/views/seat/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/questionnaire/:id",
    name: "questionnaire",
    component: () => import("@/views/questionnaire/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/questionnaire/default/:id/:miceLink",
    name: "questionnaireDefault",
    component: () => import("@/views/questionnaire/default"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/livePage/:miceLink",
    name: "LivePage",
    component: () => import("@/views/livePage/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/ebook/:linkname/:miceLink",
    name: "ebook",
    component: () => import("@/views/ebook/index"),
    meta: { title: "", icon: "", theme: "filled" }
  },
  {
    path: "/question/result/:miceLink",
    mname: "questionResult",
    component: () => import("@/views/questionnaire/result.vue"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/partMeeting/:miceLink",
    mname: "partMeeting",
    component: () => import("@/views/partMeeting/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/partMeeting/live/:miceLink",
    mname: "partMeeting",
    component: () => import("@/views/partMeeting/live"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/live/:miceLink",
    mname: "live",
    component: () => import("@/views/live/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: false }
  },
  {
    path: "/activity/:miceLink",
    name: "activity",
    component: () => import("@/views/activity/area"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/activity/:miceLink/:id",
    name: "activityInfo",
    component: () => import("@/views/activity/info"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },
  {
    path: "/ylfh2024-company",
    name: "ylfh2024-company",
    component: () => import("@/views/ylfh2024/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  },{
    path: "/total",
    name: "total",
    component: () => import("@/views/total/index"),
    meta: { title: "", icon: "", theme: "filled", keepAlive: true }
  }
];
export default subRouter;
