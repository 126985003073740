const getDefaultState = () => {
  return {
    videoList: [],
    current: {}
  }
}
const state = getDefaultState()

const mutations = {
  SET_VIDEO: (state, info) => {
    state.videoList = info
  },
  SET_CURRENT: (state, current) => {
    state.current = current
  }
}

export default {
  namespaced: true,
  state,
  mutations
}