<template>
  <div id="app">
<!--    <div>-->
    <!-- <div v-if="$route.path.includes('enterprise') || $route.path==='/'"> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
    <!-- </div> -->
    <!-- <div v-else class="tips">敬请期待</div> -->
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="less" scoped>
.tips {
  font-size: 20px;
  color: #333;
  text-align: center;
  margin-top: 20px;
}
</style>