const getDefaultState = function() {
  return {
    openid: '',
    nicename: '',
    sex: '',
    province: '',
    city: '',
    country: '',
    headimgurl: ''
  }
}

const state = getDefaultState()

const mutations = {
  SET_USER(state, user) {
    for (let key in state) {
      state[key] = user[key]
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations
}