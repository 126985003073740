
let fixedLength = (value, length = 2) => {
    return Number(value.toFixed(length))
};

let fixedNormalLength = (value, length = 2) => {
    let len = Math.pow(10, length);
    return Math.floor(value * len) / len;
};

let numberPercent = (value1, value2) => {
    let result = value1 / value2
    return result.toFixed(2)
}

let numberPer = (value) => {
    let result = value * 100;
    return Number(result.toFixed(1))
}

export { fixedLength, numberPer }
