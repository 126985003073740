let questionnaire = {
  getAnswerList() {
    return `/api/expandAnswer/getQuestions/`
  },
  answerQuestions() {
    return `/api/expandAnswer/answerQuestions`
  },
  getQuestionInfo() {
    return `/api/expandAnswer/getAnswer`
  },
  unlimitedAnswerQuestions() {
    return `/api/expandAnswer/unlimitedAnswerQuestions`
  },
  // 是否回答问题
  answerIsExist() {
    return `/api/expandAnswer/answerIsExist/`
  }
  
}
export default questionnaire