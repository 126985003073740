//用户接口
const head = "/api/admin";
let login = {
    login() {
        return `${head}`;
    },
    adminLogin() {
        return `${head}/login`;
    }
}
export default login;