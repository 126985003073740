
import login from "./models/login"
import mice from "./models/mice"
import index from './models/index'
import weixin from './models/weixin'
import userArrange from './models/userArrange'
import questionnaire from './models/questionnaire'
let baseURL = {
  login,
  mice,
  index,
  weixin,
  userArrange,
  questionnaire
}
export default baseURL;
