const getDefaultState = () => {
  return {
    miceName: '',
    miceLink: '',
    groupId: '', // 分会场Id
    groupName: '', // 分会场名称
    itineraryQueryType: '',
    posters: '',
    startTime: '',
    endTime: '',
    address: '',
    description: '',
    applyCount: '',
    departAddress: '',
    isAudit: 0,
    createBy: '',
    registerDeadline: '',
    phone: '',
    limitGroup: ''
  }
}
const state = getDefaultState()

const mutations = {
  SET_MICE_NAME(state, name) {
    state.miceName = name
  },
  SET_MICE_LINK(state, link) {
    state.miceLink = link
  },
  SET_GROUP_ID(state, id) {
    state.groupId = id
  },
  SET_GROUP_NAME(state, name) {
    state.groupName = name
  },
  SET_MICE_SEARCH_TYPE(state, type) {
    state.itineraryQueryType = type
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_MICE(state, info) {
    for (let key in state) {
      if (key === 'miceLink') {
        state[key] = info ? info.linkName : ''
      } else {
        state[key] = info ? info[key] : ''
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}