const getDefaultState = function() {
  return {
    group: {},
    groupList: {
      '7c6a4c2e9e87471e9eea4e3f68bd8c64' : {  // 岳麓论坛
        noSign: true,
        name: '岳麓论坛',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081200428661919.jpg',
        theme:'AI汇湘江 数智驱未来',
        desc: `岳麓论坛将围绕“AI汇湘江 数智驱未来”主题，把握数字化、网络化、智能化发展机遇，聚焦湖南4×4现代化产业体系，邀请院士专家、业界大咖、行业代表汇聚一堂，开展高峰对话，驱动湖南产业新发展。`,
        host: {
          name: '',
          position: ['']
        },
        organizer: ['中共湖南省委网络安全和信息化委员会办公室', '湖南省科学技术厅', '湖南省工业和信息化厅', '湖南省市场监督管理局', '湖南湘江新区管理委员会'],
        undertaker: ['中共长沙市委网络安全和信息化委员会办公室', '湖南湘江新区宣传工作部', '岳麓山大学科技城管理委员会', '长沙信息产业园管理委员会'],
        coOrganizer: [],
        zcOrganizer:['中南大学', '湖南大学', '湖南师范大学', '华南理工大学', '湖湘汇', '新湘会'],
        users: [

        ],
        contact: {
          name:'',
          phone:''
        },
        schedule: [
          // {
          //   date: '',
          //   title: '开幕式(9：00－9：45)',
          //   titleType: 'center',
          //   type: '',
          //   host: {
          //     name: ''
          //   },  
          //   tableTalk: false,
          //   people: [],
          // },
          // {
          //   date: '',
          //   title: '',
          //   type: '',
          //   host: {
          //     name: '毛伟明',
          //     position: ['省委副书记、省长']
          //   },  
          //   tableTalk: true,
          //   people: [],
          // },
          // {
          //   date: '',
          //   title: '(1)观看2023互联网岳麓峰会宣传片<br>(2)省委常委、长沙市委书记吴桂英致辞<br>(3)省委书记沈晓明致辞并宣布开幕<br>(4)万兴科技创始人、董事长兼总裁吴太兵发布《湘商回乡倡议书》<br>(5)58同城CEO姚劲波发布“十年·百人·千亿”——互联网岳麓峰会影响力白皮书<br>(6)科大讯飞董事长刘庆峰作主旨演讲<br>(7)蚂蚁集团董事长兼CEO井贤栋作主旨演讲',
          //   type: '',
          //   host: {

          //   },  
          //   tableTalk: false,
          //   people: [],
          // },


          {
            date: '',
            title: '岳麓论坛(16:00-17:10)',
            titleType: 'center',
            type: '',
            host: {
            },
            tableTalk: true,
            people: [],
          },

          {
            date: '',
            title: '',
            type: '',
            host: {
              name: '毛伟明',
              position: ['省委副书记、省长']
            },
            tableTalk: true,
            people: [],
          },
          {
            date: '',
            title: '',
            type: '',
            host: {

            },
            tableTalk: false,
            people: [
              {
                name: '',
                position: ['（1）观看2024互联网岳麓峰会宣传片']
              },
              {
                name: '',
                position: ['（2）省委常委、长沙市委书记吴桂英致辞']
              },
              {
                name: '',
                position: ['（3）省委书记沈晓明讲话']
              },
              {
                name: '',
                position: ['（4）“全球研发中心城市”成果发布及项目签约']
              },
              {
                name: '',
                position: ['（5）IDG 资本创始董事长熊晓鸽作主旨演讲']
              },
              {
                name: '',
                position: ['（6）新紫光集团有限公司董事长李滨作主旨演讲']
              },
            ]
          },

          // {
          //   date: '',
          //   title: '主旨演讲《乘“数”而上，以“智”取胜》',
          //   type: '',
          //   host: {
          //   },  
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '李建宇',
          //       position: ['湖南钢铁集团副董事长、总经理，华菱钢铁董事长、总经理']
          //     },
          //   ],
          // },
          // {
          //   date: '',
          //   title: '主旨演讲《数字经济与湖南的发展机遇》',
          //   type: '',
          //   host: {
          //   },  
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '李三希',
          //       position: ['中国人民大学教授，中国人民大学数字经济研究中心主任']
          //     },
          //   ],
          // },
          {
            date: '',
            title: '圆桌论坛(17:10-17:40)',
            titleType: 'center',
            type: '',
            host: {
            },
            tableTalk: false,
            people: [],
          },
          {
            date: '',
            title: '',
            type: '',
            host: {
              name: '姚颂',
              position: ['东方空间(山东)科技有限公司联合创始人、联席CEO']
            },
            tableTalk: true,
            people: [
              {
                name: '何忠江',
                position: ['中电信人工智能科技(北京)有限公司董事长']
              },
              {
                name: '姚项军',
                position: ['京东方科技集团执行副总裁、艺云科技董事长']
              },
              {
                name: '汤同奎',
                position: ['上海维宏电子科技股份有限公司董事长']
              },
              {
                name: '段爱国',
                position: ['上海依图网络科技有限公司总裁']
              },
              {
                name: '陈文正',
                position: ['前海方舟资产管理有限公司总裁、前海母基金执行合伙人']
              },
            ]
          },
        ],

      },
      '819064df615a4786b7883d0b8e686c32':{
        contact: {
          name:'刘琛',
          phone:'19896241073'
        },
        desc: '开幕式|岳麓论坛：岳麓峰会其实是一群湖南人“策”起来的。2014年，时任湖南省省长杜家毫在北京组织一场湘籍人士座谈会。祖籍湘潭的IDG资本创始人熊晓鸽在为振兴家乡献计献策时谈到，过去十年，虽然湖南出了动漫湘军、电视湘军、出版湘军、制造湘军等，但基本上都是在传统行业，站在移动互联网的拐点，湖南有人才基础、有现实优势。',
        schedule: [
          {
            date: '9:00--9:30',
            title: '开幕式',
            type: '',
            host: {
              name: '吴桂英',
              position: ['湖南省委常委、长沙市委书记']
            },
            people: [
              {
                name: '出席领导：毛伟明、李殿勋、吴桂英、陈飞、邓群策及湖南省委网络安全和信息化委员会办公室、省发改委、省科学技术厅、省工业和信息化厅、省商务厅、长沙市、株洲市、湘潭市及湘江新区等单位负责同志'
              }
            ]
          },
          {
            date: '09:40-09:58',
            title: '主旨演讲：岳麓论坛',
            type: '主题演讲',
            host: {
              name: '',
              position: ['长沙广播电视台专业主持人']
            },
            people: [
              {
                name: '吴晓吴晓如(科大讯飞总裁)、孙洪水(中国能建党委副书记、总经理)、徐林(中美绿色基金董事长)、王柏华(浪潮集团执行总裁)、陈晓红(中国工程院院士)等5人如',
                position: ['']
              },
            ]
          },
          {
            date: '11:00--12:00',
            title: '圆桌论坛',
            type: '主题演讲',
            host: {
              name: '姚劲波',
              position: ['58同城CEO']
            },
            people: [
              {
                name: '许诗军(阿里集团副总裁)、齐向东(奇安信董事长)、徐少春(金蝶集团CEO)、吴宗友(海光信息首席运营官)、刘宁(招商局蛇口副总经理、董事会秘书)、胡军(同方股份CEO)等6人',
                position: []
              }
            ]
          }
        ]
      },
      'ede6ce971a9d4e92b4183894855a7078': { // 专场3： 提升数字素养，助力网络强省
        name: '提升数字素养，助力网络强省',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306161118228438253.png',
        desc: '智能制造分论坛围绕“智能制造  领航未来”主题，邀请中国工程院院士、中国工业互联网研究院、中科云谷、京东、商汤、华为等业界专家、智库、企业代表，聚焦打造先进制造业高地，探讨“数智化”新时代智能制造助力科技创新与产业升级。',
        organizer: ['2024互联网岳麓峰会组委会'],
        undertaker: ['湖南湘江新区智能制造装备和工程机械产业链办公室','中科云谷科技有限公司', '长沙智能制造研究总院有限公司', '湖南金蝶软件科技有限公司'],
        coOrganizer: [],
        host: {
          name: '黄健',
          position: [
            '湖南卫视主持人'
          ]
        },
        contact: {
          name:'邓新如',
          phone:'15874068410'
        },
        // module: {
        //   name: '主旨演讲',
        //   host: {
        //     nmae: '姚劲波',
        //     position: ['58同城CEO']
        //   }
        // },
        users: [
          {
            name: "李培根",
            company: "中国工程院院士、国家智能制造专家委员会主任"
          },
          {
            name: "李炜",
            company: "中国工业互联网研究院党委副书记"
          },
          {
            name: "付玲",
            company: "中联重科副总裁、总工程师、中科云谷科技有限公司董事长"
          },
          {
            name: "郭旭光",
            company: "金蝶中国助理总裁"
          },
          {
            name: "周炯",
            company: "京东集团副总裁"
          },
          {
            name: "赵敏",
            company: "走向智能研究院执行院长"
          },
          {
            name: "曾光",
            company: "中联重科IT负责人、中科云谷科技有限公司总经理"
          },
          {
            name: "赵峰",
            company: "商汤科技智慧城市与商业事业群副总裁、企业业务总经理"
          },
          {
            name: "丁国祥",
            company: "华为全球制造业首席专家"
          },
          {
            name: "黄文宝",
            company: "华自科技股份有限公司董事长"
          },
          {
            name: "孙海",
            company: "中国电信集团工业产业研究院院长"
          },
        ],
        schedule: [
          {
            date: '14:00-14:05',
            title: '主持人开场',
            type: '',
            host: {},
            tableTalk: false,
            people: [
            ]
          },
          {
            date: '14:05-14:10',
            title: '领导致辞',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              // {
              //     name: '周凡',
              //     position: ['湖南湘江新区(长沙高新区)党工委副书记、岳麓区委副书记']
              // }
            ]
          },
          {
            date: '14:10-14:20',
            title: '中科云谷落户湘江新区仪式',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              // {
              //     name: '陈澎',
              //     position: ['市委常委、市委宣传部部长、市社科联主席']
              // }
            ]
          },
          {
            date: '',
            title: '湘商回归—中科云谷回迁仪式宣传片播放',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              // {
              //     name: '陈澎',
              //     position: ['市委常委、市委宣传部部长、市社科联主席']
              // }
            ]
          },
          {
            date: '14:20-14:40',
            title: '《智能制造的方向和路径》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '杨学山',
                position: ['工业和信息化部原副部长']
              }
            ]
          },
          {
            date: '14:40-15:00',
            title: '《装备智能制造趋势一二》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '李培根',
                position: ['中国工程院院士、国家智能制造专家委员会主任']
              }
            ]
          },
          {
            date: '15:00-15:20',
            title: '《智慧引领、数字赋能，天翼云工业互联网助力产业升级》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '孙  海',
                position: ['中国电信集团工业产业研究院院长']
              }
            ]
          },
          {
            date: '15:20-15:40',
            title: '《工业互联网平台推动制造业数字化转型》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '李  炜',
                position: ['中国工业互联网研究院 党委副书记']
              }
            ]
          },
          {
            date: '15:40-15:55',
            title: '《数字领航 打造先进制造业高地》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '付  玲',
                position: ['中联重科副总裁、总工程师、中科云谷科技有限公司董事长']
              }
            ]
          },
          {
            date: '15:55-16:10',
            title: '《对标世界一流，构建制造业数字化运营平台》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '郭旭光',
                position: ['金蝶中国助理总裁']
              }
            ]
          },
          {
            date: '16:10-16:25',
            title: '《发展工业互联网平台，助力实体产业数智化升级》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '周  炯',
                position: ['京东集团副总裁']
              }
            ]
          },
          {
            date: '16:25-16:55',
            title: '圆桌论坛：《“数智化”新时代智能制造助力科技创新与产业升级》',
            type: '',
            host: {
              name: '曾光',
              position: ['中联重科IT负责人、中科云谷科技有限公司总经理']
            },
            tableTalk: true,
            people: [
              {
                name: '赵敏',
                position: ['走向智能研究院执行院长']
              },
              {
                name: '赵峰',
                position: ['商汤科技智慧城市与商业事业群副总裁、企业业务总经理']
              },
              {
                name: '丁国祥',
                position: ['华为全球制造业首席专家']
              },
              {
                name:'黄文宝',
                position: ['华自科技股份有限公司董事长']
              }
            ]
          },
        ],
        noSign: true
      },
      '54a076975bdb4e4e8d8ccdb00e5e133f': {  // 峰会新十年  湘商新征程
        name: '峰会新十年  湘商新征程',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081158477780363.jpg?imageView2/2/w/750',
        desc: '“峰会新十年  湘商新征程”专场围绕湘商回归、校友回湘，拟邀熊晓鸽、姚劲波、李新宇、徐少春、倪正东、唐岳等全国知名湘籍企业家，深度分享科技变革下的企业创新发展之道，共话现代化新湖南建设中的产业新机遇，汇赤子之心、建美好家乡。',
        theme:'峰会新十年  湘商新征程',
        organizer: ['2024互联网岳麓峰会组委会'],
        undertaker: ['长沙市商务局', '湖南湘江新区商务和市场监管局','互联网岳麓峰会企业家','新湘会','湖南大学校友企业家联谊会(麓友会)'],
        zdOrganizer:['湖南省商务厅','湖南省国资委'],
        zxOrganizer: ['湖南湘江新区国有资本投资有限公司', '湘江基金小镇'],
        coOrganizer: ['拓维信息系统股份有限公司','湖南广播影视集团有限公司','万兴科技集团股份有限公司','水羊集团股份有限公司','湖南茶悦文化产业发展集团有限公司'],
        zcOrganizer: [ '上海证券交易所'],
        host: {
          name: '许静',
          position: ['长沙广电']
        },
        contact: {
          name: '张廷翔',
          phone: '15580085756'
        },
        users: [
          {
            name: '刘昼',
            company: '达晨财智创始合伙人、董事长'
          },
          {
            name: '叶大清',
            company: '融360联合创始人、董事长、首席执行官'
          },
          {
            name: '陈文正',
            company: '前海方舟资产管理有限公司总裁、前海母基金执行合伙人'
          },
          {
            name: '姜桂林',
            company: '财信金控首席信息官，财信数科执行董事、总经理，数字湖南总经理'
          },
          {
            name: '赵旭',
            company: '联想创投集团副总裁'
          },
          {
            name: '邹树平',
            company: '深圳市创新投资集团有限公司执行总经理、华中片区总经理'
          },

          {
            name: '储慧斌',
            company: '海捷投资控股集团董事长'
          },
          {
            name: '李砚',
            company: '中国银行湖南省分行行长助理'
          },
          {
            name: '熊钢',
            company: '澳银资本董事长、创始合伙人'
          },
          {
            name: '李银亮',
            company: '海创汇创投总经理、海尔海创汇董事'
          },
        ],
        schedule: [
          {
            date: '09:00-09:20',
            title: '领导讲话',
            type: '',
            host:{},
            tableTalk: false,
            people: []
          },
          {
            date: '09:20-09:30',
            title: '仪式环节：',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '湘商企业家代表共同宣读《湘商共识》',
              },
              {
                name: '开启湘商新征程仪式',
              },
            ]
          },
          {
            date: '09:30-09:40',
            title: '主题演讲1：敢为人先，三“湘”巨变',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '李 泽 湘',
                position: ['XbotPark机器人基地发起人']
              }
            ]
          },
          {
            date: '09:40-09:50',
            title: '主题演讲2：人工智能新启程 湖南奋进谱华章',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '姚 劲 波',
                position: ['58同城CEO']
              }
            ]
          },
          {
            date: '09:50-10:00',
            title: '主题演讲3：以科技创新，开启人类航天新征途 ',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '姚颂',
                position: ['东方空间联合创始人、联席CEO']
              }
            ]
          },
          {
            date: '10:00-10:30',
            title: '圆桌论坛1：创新湘潮：现代化新湖南建设中的发展新机遇',
            type: '',
            host: {
              name:'熊晓鸽',
              position:['IDG资本创始董事长']
            },
            tableTalk: true,
            people: [
              {
                name: '徐少春、李新宇、龚虹嘉、邱中伟',
              },
            ]
          },
          {
            date: '10:30-11:05',
            title: '圆桌论坛2：文化与科技融合，驱动湖南产业新未来',
            type: '',
            host: {
              name: '陈湘宇',
              position: ['创梦天地(乐逗游戏)CEO ']
            },
            tableTalk: true,
            people: [
              {
                name: '倪正东、曾雄、吕良、罗灿、李炜',
              }
            ]
          },
          // {
          //   date: '17:00-17:15',
          //   title: '智谷推介：万物互联，万亿生态',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '彭振文',
          //       position: ['长沙软件园董事长、总经理']
          //     }
          //   ]
          // },
          // {
          //   date: '16:20-17:30',
          //   title: '项目路演',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '1、神经元智能',
          //       position: ['']
          //     },
          //     {
          //       name: '2、臻固固态电池',
          //       position: ['']
          //     },
          //     {
          //       name: '3、澎峰科技',
          //       position: ['']
          //     },
          //     {
          //       name: '4、深维超炫科技',
          //       position: ['']
          //     },
          //     {
          //       name: '5、星汉数智',
          //       position: ['']
          //     },
          //     {
          //       name: '6、睿图智能',
          //       position: ['']
          //     }
          //   ]
          // }
        ],
      },
      '35ca9c7f22614d8e82cc791d83436b7d': { // 专场4： 文化智核  科技新篇
        name: '文化智核  科技新篇',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081200039223752.jpg',
        desc: '“文化智核  科技新篇”专场以探索文化和科技融合的有效机制，加快发展新型文化业态，形成更多新的文化产业增长点为主线，邀请知名行业联盟、研究机构、头部企业从趋势解读、成果发布、实践分享等方面，共同探讨产业融合发展新机遇新思路。  ',
        theme:'文化智核  科技新篇',
        organizer: ['2024互联网岳麓峰会组委会'],
        undertaker: ['马栏山(长沙)视频文创园管理委员会','中南大学网络文学研究院','湖南湘江新区宣传工作部','长沙市软件和信息技术服务业促进会'],
        coOrganizer: [],
        zdOrganizer:['中共湖南省委宣传部','中共湖南省委网络安全和信息化委员会办公室'],
        zcOrganizer: ['万兴科技集团股份有限公司','拓维信息系统股份有限公司', '长沙软件园有限公司','湖南省软件行业协会','长沙市软件和信息技术服务业促进会', '长沙市移动互联网产业联盟'],
        host: {
          name: '刘卫武',
          position: ['犀牛之星集团董事长','新湘会发起人']
        },
        contact: {
          name:'熊洛',
          phone:'18108402577'
        },
        users: [
          {
            name: "刘经南",
            company: "中国工程院院士，武汉大学教授，博士生导师，国家卫星定位系统工程技术研究中心主任"
          },
          {
            name: "张晓东",
            company: "乌镇智库理事长"
          },

          {
            name: "梁伟",
            company: "中国电信研究院大数据与人工智能研究所所长"
          },
          {
            "name": "陈维良",
            "company": "沐曦集成电路(上海)有限公司创始人、董事长兼CEO"
          },
          {
            name: "李传军",
            company: "曙光信息产业股份有限公司副总裁"
          },
          {
            name: "吴太兵",
            company: "万兴科技集团股份有限公司董事长"
          },
          {
            name: "陈花",
            company: "竞网集团联合创始人"
          },
          {
            name: "尤鹏",
            company: "华为云计算公司大数据与人工智能领域总裁"
          },
          {
            name: "何山",
            company: "科大讯飞研究院AI科学家"
          },
          {
            name: "何贺",
            company: "商汤科技大装置事业群副总裁"
          },
          {
            name: "吴军华",
            company: "百度智能云华东区总经理"
          },
          {
            name: "姜迅",
            company: "云从科技副总裁/数据研究院院长"
          },
        ],
        schedule: [
          {
            date: '09:00-09:15',
            title: '领导讲话',
            host: {},
            tableTalk: false,
            type: '',
            people: []
          },
          {
            date: '09:15-09:30',
            title: '《中国网络文学年鉴(2023)》发布及解读',
            host: {},
            tableTalk: false,
            type: '',
            people: [
              {name:'欧阳友权',position:['中南大学网络文学研究院院长、二级教授']}
            ]
          },
          {
            date: '09:30-09:45',
            title: '主题演讲 1：音视频产业发展趋势',
            host: {},
            tableTalk: false,
            // isSeparate: 1,
            type: '',
            people: [
              {
                name: '温晓君',
                position: ['中国电子信息产业发展研究院网络安全研究所所长 ']
              }
            ]
          },
          {
            date: '09:45-10:00',
            title: '主题演讲 2：市场换生态，打造自主可控的音视频产业',
            host: {},
            tableTalk: false,
            type: '',
            people: [{name: '高 戟',position:['华为音视频标准与产业发展策略委员会主任','华为 2012 实验室副主任','世界超高清视频产业联盟副理事长']}]
          },
          {
            date: '10:00-10:15',
            title: '主题演讲 3：AIGC 助力文化传媒产业发展',
            host: {},
            tableTalk: false,
            type: '',
            people: [{name: '何亚明',position:['阿里云视频云总经理']}]
          },
          {
            date: '10:15-10:30',
            title: '主题演讲 4：文化出海新浪潮与加速度',
            host: {},
            tableTalk: false,
            type: '',
            people: [{name: '吴太兵',position:['万兴科技创始人、董事长']}]
          },
          {
            date: '10:30-10:45',
            title: '主题演讲 5：AI 智能体进景区，引领公共空间场景创新',
            host: {},
            tableTalk: false,
            type: '',
            people: [{name: '李长霖',position:['北京甲板智慧科技有限公司负责人','北林人工智能研究院副院长','中国风景园林学会信息分会副会长']}]
          },
          // {
          //   date: '15:15-15:30',
          //   title: '《打造高效算力 推进产业数智化》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '陈维良',position:['沐曦集成电路(上海)有限公司创始人、董事长兼CEO']}]
          // },
          // {
          //   date: '15:30-15:45',
          //   title: '《加快构建算力网络，助力算力经济发展》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '李传军',position:['曙光信息产业股份有限公司副总裁']}]
          // },
          // {
          //   date: '15:45-16:00',
          //   title: '《大模型 小应用 创意软件AI时刻》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '吴太兵',position:['万兴科技集团股份有限公司董事长']}]
          // },
          // {
          //   date: '16:00-16:30',
          //   title: '圆桌论坛：《AI大模型如何助力产业智能化升级？》',
          //   host: {
          //     name: '陈  花',
          //     position: ['竞网集团联合创始人']
          //   },
          //   tableTalk: true,
          //   type: '',
          //   people: [
          //     {
          //       name: '尤  鹏 ',
          //       position: ['华为云计算公司大数据与人工智能领域总裁']
          //     },
          //     {
          //       name: '何  山',
          //       position: ['科大讯飞研究院AI科学家']
          //     },
          //     {
          //       name: '何  贺',
          //       position: ['商汤科技大装置事业群副总裁']
          //     },
          //     {
          //       name: '吴军华',
          //       position: ['百度智能云华东区 总经理']
          //     },
          //     {
          //       name: '姜  迅',
          //       position: ['云从科技副总裁/数据研究院院长']
          //     },
          //   ]
          // },
        ],
      },
      '525afbdaac6c4478b8c6f1391d453aca': { // 专场4： 投资新趋势 产业新格局
        name: '投资新趋势 产业新格局',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081201153486567.jpg',
        desc: '“投资新趋势 产业新格局”专场围绕新时代下投资发展和产业变革新趋势，邀请达晨财智、中信银行、春华资本、财信金控、湘江国投、长投控股等机构代表，探讨新形势下的投资趋势，破解耐心资本对于新旧动能转换、高质量发展的贡献密码。',
        theme:'投资新趋势 产业新格局',
        organizer: ['2024互联网岳麓峰会组委会'],
        undertaker: ['湖南湘江新区财政金融局','达晨财智创业投资管理有限公司','中信银行股份有限公司长沙分行'],
        zdOrganizer:['中共湖南省委金融委员会办公室','中国证券监督管理委员会湖南证监局'],
        coOrganizer: ['湖南财信金融控股集团有限公司','长沙投资控股集团有限公司','湖南湘江新区国有资本投资有限公司','达晨上市公司联盟','中信证券股份有限公司','中信建投证券股份有限公司','长沙麓谷高新移动互联网创业投资有限公司'],
        zcOrganizer: ['万兴科技集团股份有限公司','拓维信息系统股份有限公司', '长沙软件园有限公司','湖南省软件行业协会','长沙市软件和信息技术服务业促进会', '长沙市移动互联网产业联盟'],
        host: {
          name: '刘卫武',
          position: ['犀牛之星集团董事长','新湘会发起人']
        },
        contact: {
          name:'袁思嘉',
          phone:'18806644170'
        },
        users: [
          {
            name: "刘经南",
            company: "中国工程院院士，武汉大学教授，博士生导师，国家卫星定位系统工程技术研究中心主任"
          },
          {
            name: "张晓东",
            company: "乌镇智库理事长"
          },

          {
            name: "梁伟",
            company: "中国电信研究院大数据与人工智能研究所所长"
          },
          {
            "name": "陈维良",
            "company": "沐曦集成电路(上海)有限公司创始人、董事长兼CEO"
          },
          {
            name: "李传军",
            company: "曙光信息产业股份有限公司副总裁"
          },
          {
            name: "吴太兵",
            company: "万兴科技集团股份有限公司董事长"
          },
          {
            name: "陈花",
            company: "竞网集团联合创始人"
          },
          {
            name: "尤鹏",
            company: "华为云计算公司大数据与人工智能领域总裁"
          },
          {
            name: "何山",
            company: "科大讯飞研究院AI科学家"
          },
          {
            name: "何贺",
            company: "商汤科技大装置事业群副总裁"
          },
          {
            name: "吴军华",
            company: "百度智能云华东区总经理"
          },
          {
            name: "姜迅",
            company: "云从科技副总裁/数据研究院院长"
          },
        ],
        schedule: [
          {
            date: '09:00-09:15',
            title: '领导讲话',
            host: {},
            tableTalk: false,
            type: '',
            people: []
          },
          {
            date: '09:15-09:30',
            title: '主题演讲 1：鼓励耐心资本进入创投行业，助推新质生产力加快发展',
            host: {},
            tableTalk: false,
            type: '',
            people: [
              {name:'刘 昼',position:['达晨财智创业投资管理有限公司创始合伙人、董事长']}
            ]
          },
          {
            date: '09:30-10:00',
            title: '主题演讲 2：处理好十大关系，激励创投支持新质生产力',
            host: {},
            tableTalk: false,
            // isSeparate: 1,
            type: '',
            people: [
              {
                name: '刘健钧',
                position: ['湖南大学教授、博士生导师 ']
              }
            ]
          },
          {
            date: '10:00-10:15',
            title: '主题演讲 3：大模型时代下的创新创业',
            host: {},
            tableTalk: false,
            type: '',
            people: [{name: '王绍兰',position:['智谱 AI 总裁']}]
          },
          {
            date: '10:15-10:30',
            title: '主题演讲 4：当前我国宏观经济形势及投资机遇展望',
            host: {},
            tableTalk: false,
            type: '',
            people: [{name: '胡玉玮',position:['中信建投证券首席政策分析师']}]
          },
          {
            date: '10:30-11:00',
            title: '圆桌论坛：创投助推新质生产力发展',
            host: {
              name:'傅忠红',
              position:['达晨财智创业投资管理有限公司主管合伙人']
            },
            tableTalk: true,
            type: '',
            people: [
              {name: '曾之杰',position:['元禾厚望创新成长基金创始管理合伙人']},
              {name: '陈文正',position:['前海方舟资产管理有限公司总裁、前海母基金执行合伙人']},
              {name: '刘天学',position:['湖南省财信产业基金管理有限公司董事长']},
              {name: '刘 苗',position:['长投控股集团董事、副总经理']},
              {name: '王 志',position:['深圳市联软科技股份有限公司董事长']},
            ]
          },
          // {
          //   date: '15:00-15:15',
          //   title: '《推动AI新型基础设施与领域大模型融合发展》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '梁  伟',position:['中国电信研究院大数据与人工智能研究所所长']}]
          // },
          // {
          //   date: '15:15-15:30',
          //   title: '《打造高效算力 推进产业数智化》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '陈维良',position:['沐曦集成电路(上海)有限公司创始人、董事长兼CEO']}]
          // },
          // {
          //   date: '15:30-15:45',
          //   title: '《加快构建算力网络，助力算力经济发展》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '李传军',position:['曙光信息产业股份有限公司副总裁']}]
          // },
          // {
          //   date: '15:45-16:00',
          //   title: '《大模型 小应用 创意软件AI时刻》',
          //   host: {},
          //   tableTalk: false,
          //   type: '',
          //   people: [{name: '吴太兵',position:['万兴科技集团股份有限公司董事长']}]
          // },
          // {
          //   date: '16:00-16:30',
          //   title: '圆桌论坛：《AI大模型如何助力产业智能化升级？》',
          //   host: {
          //     name: '陈  花',
          //     position: ['竞网集团联合创始人']
          //   },
          //   tableTalk: true,
          //   type: '',
          //   people: [
          //     {
          //       name: '尤  鹏 ',
          //       position: ['华为云计算公司大数据与人工智能领域总裁']
          //     },
          //     {
          //       name: '何  山',
          //       position: ['科大讯飞研究院AI科学家']
          //     },
          //     {
          //       name: '何  贺',
          //       position: ['商汤科技大装置事业群副总裁']
          //     },
          //     {
          //       name: '吴军华',
          //       position: ['百度智能云华东区 总经理']
          //     },
          //     {
          //       name: '姜  迅',
          //       position: ['云从科技副总裁/数据研究院院长']
          //     },
          //   ]
          // },
        ],
      },
      '9097ee8638ae4c7993efc19dd2a12dbc': { // 专场6： 元宇宙
        name: '湘约新宇宙，共创新时代',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202208271923370231072.png?imageView2/2/w/750',
        organizer: ['岳麓峰会组委会'],
        undertaker: ['万兴科技', '风语筑','草花互动'],
        coOrganizer: [],
        zcOrganizer: ['湖南省移动互联网协会','湖南省区块链协会','元宇宙三十人论坛','长沙市移动互联网及应用软件产业链推进br办公室'],
        desc: '“湘约新宇宙，共创新时代”专场，长沙已跻身“互联网产业第五城”和“元宇宙中部第一城”。作为元宇宙专场，邀请了元宇宙三十人论坛学术与技术委员会主席、中共第十八届中央委员林左鸣等领域专家，论道元宇宙前沿趋势和落地实践，分享中国经验和中国方案，并将发起成立中国首个元宇宙创作者生态联盟，共抓数字经济新机遇，共拓高质量发展新空间。',
        host: {
          name: '常  婷',
          position: ['中央广播电视总台主持人']
        },
        contact: {
          name:'蔡育珊',
          phone:'15999684206'
        },
        schedule: [
          {
            date: '08:30-09:00',
            title: '会议签到、嘉宾入场',
            type: '',
            host: {},
            tableTalk: false,
            people: []
          },
          // {
          //   date: '09:00-09:05',
          //   title: '主持人介绍现场嘉宾',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [

          //   ]
          // },
          {
            date: '09:00-09:20',
            title: '领导致辞',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '谭  勇',
                position: ['市委副书记、湖南湘江新区党工委书记、岳麓区委书记']
              },
              {
                name: '刘 学',
                position: ['湖南省委宣传部副部长、省委网信办主任']
              },
              {
                name: '赖明勇',
                position: ['湖南省政协副主席、民建湖南省委会主委']
              }
            ]
          },
          // {
          //   date: '09:10-09:15',
          //   title: '领导致辞',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '刘  学',
          //       position: ['省委宣传部副部长、省委网信办主任']
          //     },
          //   ]
          // },
          // {
          //   date: '09:15-09:20',
          //   title: '领导致辞',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '赖明勇',
          //       position: ['省政协副主席、民建湖南省委会主委']
          //     },
          //   ]
          // },
          {
            date: '09:20-09:25',
            title: '湖南湘江新区元宇宙产业政策发布',
            type: '',
            host: {},
            tableTalk: false,
            people: [
            ]
          },
          {
            date: '09:25-09:40',
            title: '主旨演讲:《元宇宙与软价值》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '滕  泰',
                position: ['万博新经济研究院院长、元宇宙三十人论坛学','术与技术委员会副主席']
              }
            ]
          },
          {
            date: '09:40-09:55',
            title: '主旨演讲:《元宇宙的十大趋势》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '易欢欢',
                position: ['易股天下集团董事长、盘古智库发起人']
              }
            ]
          },
          {
            date: '09:55-10:10 ',
            title: '主题演讲:元宇宙时代的数字创意新趋势探索和实践',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '吴太兵',
                position: ['万兴科技集团股份有限公司董事长']
              }
            ]
          },
          {
            date: '10:10-10:25',
            title: '主题演讲:2022全球元宇宙创作者经济白皮书',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '吕荣慧',
                position: ['艾瑞咨询集团副总裁']
              }
            ]
          },
          {
            date: '10:25-10:40',
            title: '主旨演讲：《元宇宙时代场景应用的探索和实践》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '李  晖',
                position: ['上海风语筑文化科技股份有限公司董事长']
              }
            ]
          },
          {
            date: '10:40-10:55',
            title: '主旨演讲：《区块链：元宇宙的底层核心技术》',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '谭  林',
                position: ['湖南天河国云科技有限公司董事长兼CEO','湖南省区块链协会会长']
              }
            ]
          },
          {
            date: '10:55-11:10',
            title: '主旨演讲：《云网算力赋能元宇宙 助力数字经济腾飞》 ',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name:'万  鹏',
                position: ['中国电信湖南公司党委副书记、副总经理']
              }
            ]
          },
          {
            date: '11:10-11:55',
            title: '圆桌论坛：《元宇宙如何助力湖南经济发展》 ',
            type: '',
            host: {
              name:'吴太兵',
              position:['万兴科技集团股份有限公司董事长']
            },
            tableTalk: true,
            people: [
              {
                name:'戴建清',
                position: ['湖南草花互动科技股份公司董事长']
              },
              {
                name:'李亚雄',
                position: ['世界VRAR协会中国区负责人','VRAR星球创始人']
              },
              {
                name: '肖征宇',
                position: ['湖南芒果幻视科技有限公司总经理']
              },
              {
                name:'赵俊杰',
                position: ['广州引力波信息科技有限公司创始人']
              },
              {
                name:'王建新',
                position: ['中南大学计算机学院院长，教授、博士生导师']
              },
            ]
          },
          {
            date: '11:55-12:00',
            title: '联盟成立仪式：元宇宙创作者生态联盟成立',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              // {
              //   name:'吴太兵',
              //   position: ['万兴科技集团股份有限公司董事长']
              // },
              // {
              //   name:'李  晖',
              //   position: ['上海风语筑文化科技股份有限公司董事长']
              // },
              // {
              //   name:'戴建清',
              //   position: ['湖南草花互动科技股份公司董事长']
              // },
              // {
              //   name:'夏晓晖',
              //   position: ['映宇宙集团高级副总裁、总编辑']
              // },
              // {
              //   name:'谭  林',
              //   position: ['天河国云董事长兼CEO、湖南省区块链协会会长']
              // },
              // {
              //   name:'肖征宇',
              //   position: ['湖南芒果幻视科技有限公司总经理']
              // },
              // {
              //   name:'赵俊杰',
              //   position: ['广州引力波信息科技有限公司创始人']
              // }
            ]
          },
        ]
      },
      '8355b261ea5a4559ab727ba647814891': { // 产业互联网 智汇长沙城
        name: '产业互联网 智汇长沙城',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081201525040829.jpg?imageView2/2/w/750',
        desc: '“产业互联网 智汇长沙城专场”以“融合创新·共筑产业互联网发展高地”为主题，拟邀请国联股份、SHEIN、泰坦科技、易久批、汇通达、河钢数字等机构代表，围绕产业互联网平台如何引领中国线下产业集群数字化、如何发挥数据要素潜能和进行AI融合，探索城市和个人在本轮中国产业互联网热点和拐点之下的新机遇。',
        theme:'融合创新·共筑产业互联新生态',
        organizer: ['2024互联网岳麓峰会组委会'],
        undertaker: ['中国信息协会产业互联网分会','湖南湘江新区科技创新和产业促进局','湖南电子信息协会产业互联网分会'],
        zxOrganizer: ['湘江新区新材料产业链联盟', '中南大学科技园发展有限公司'],
        coOrganizer: ['湖南新青年创新创业研究院','湖南机械之家信息科技有限公司','湖南医聘猫科技有限公司','长沙争渡网络科技有限公司','湖南惠农科技有限公司','湖南光芒时尚控股集团有限公司'],
        zdOrganizer:['湖南省发展和改革委员会','湖南省工业和信息化厅'],
        zcOrganizer: ['中国有色金属学会', '中国有色金属产业技术创新战略联盟', '中南大学粉末冶金工程研究中心有限公司', '长沙新型合金材料产业集群'],
        host: {
        },
        contact: {
          name: '林珊',
          phone: '18974857257'
        },
        users: [

          {
            name: "周科朝",
            company: "中南大学粉末冶金国家重点实验室主任、教授"
          },
          {
            name: "姜锋",
            company: "中南大学粉末冶金工程研究中心有限公司总裁、教授"
          },
          {
            name: "冯建伟",
            company: "中科粉研(河南)超硬材料有限公司董事长"
          },
          {
            name: "肖健",
            company: "深圳市赛瑞产业研究有限公司总经理"
          },
          {
            name: "李星辉",
            company: "航天科工新材料研究院有限公司院长"
          },
          {
            name: "聂东红",
            company: "湖南东方钪业股份有限公司总经理"
          },
          {
            name: "闫硕",
            company: "中伟新材料股份有限公司首席专家兼研究院院长"
          },
          {
            name: "董思科",
            company: "湖南湘江新区新材料(含新型合金、3D打印)产业链盟长"
          },
          {
            name: "李安民",
            company: "北京久银投资控股股份有限公司董事长，北京长沙企业商会会长"
          },
          {
            name: "马世光",
            company: "中国有色金属产业技术创新战略联盟常务副理事长、教授级高级工程师"
          },
          {
            name: "刘玉强",
            company: "中国产学研合作促进会党委委员、中国矿产资源与材料应用协同创新平台专职副理事长"
          },
          {
            name: "衣进",
            company: "麻省理工学院博士、质合创投创始合伙人"
          },
          {
            name: "杨胜",
            company: "湖南湘投金天科技集团有限责任公司首席专家、总经理"
          },
          {
            name: "姚可夫",
            company: "清华大学材料学院教授"
          },
          {
            name: "刘刚",
            company: "西安交通大学金属材料强度国家重点实验室副主任、教授"
          },
          {
            name: "曾德长",
            company: "广东正德材料表面科技有限公司董事长"
          },
        ],
        schedule: [
          // {
          //   date: '',
          //   title: '第一阶段(14:00-14:30)',
          //   titleType: 'center',
          //   type: '',
          //   host: {
          //   },
          //   tableTalk: true,
          //   people: [],
          // },
          // {
          //   date: '',
          //   title: '',
          //   type: '',
          //   host: {
          //     name: '张  柳',
          //     position: ['湖南湘江新区统战工作部(群团工作部)部长、岳麓区委常委、统战部长、新材料(含新型合金、含3D打印)产业链办公室主任']
          //   },
          //   tableTalk: true,
          //   people: []
          // },
          {
            date: '09:00-09:15',
            title: '领导讲话',
            type: '',
            host: {},
            tableTalk: false,
            people: []
          },
          {
            date: '09:15-09:25',
            title: '《中国产业互联网平台数据地图》及系列图谱发布',
            type: '',
            host: {
              name: '王 静',
              position: ['中国信息协会产业互联网分会专家组组长']
            },
            tableTalk: false,
            people: [
              // {
              //   name: '罗社辉',
              //   position: ['湖南湘江新区(长沙高新区)党工委委员管委会副主任、一级巡视员']
              // }
            ]
          },
          {
            date: '09:25-09:30',
            title: '倡议发布：产业互联网领域企业代表上台发布“万亿中流”倡议',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              // {
              //   name: '罗社辉',
              //   position: ['湖南湘江新区(长沙高新区)党工委委员管委会副主任、一级巡视员']
              // }
            ]
          },
          {
            date: '09:30-09:45',
            title: '主题演讲 1：全球产业格局与中国产业互联网的挑战与机遇',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name:'何志毅',
                position:[
                  '清华大学全球产业研究院首席专家',
                  '北京大学光华管理学院教授','中国上市公司协会学术顾问委员会副主任委员'
                ]
              }
            ]
          },
          // {
          //   date: '',
          //   title: '第二阶段(14:30-15:10)',
          //   titleType: 'center',
          //   type: '',
          //   host: {
          //   },
          //   tableTalk: true,
          //   people: [],
          // },
          {
            date: '09:45-10:00',
            title: '主题演讲 2：激活人工智能新动力，开创产业高质量发展新局面',
            type: '',
            host: {
            },
            tableTalk: false,
            people: [
              {
                name: '马 斌',
                position: ['腾讯集团副总裁']
              },
            ]
          },
          {
            date: '10:00-10:15',
            title: '主题演讲 3：产业互联网如何引领绿色化发展',
            type: '',
            host: {
            },
            tableTalk: false,
            people: [
              {
                name: '申 培',
                position: ['河钢数字党委书记、总经理']
              },
            ]
          },
          {
            date: '10:15-10:30',
            title: '主题演讲 4：如何运用产业互联网和产业基金工具，发展平台、股权、数据三大新财政和打造湖南产业链数字化平台新经济',
            type: '',
            host: {
            },
            tableTalk: false,
            people: [
              {
                name: '廖 登',
                position: ['蓝源资本 CTO']
              }
            ]
          },
          {
            date: '10:30-10:40',
            title: '主题演讲 5：产业互联网平台如何赋能中国农业工程机械领域',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '朱 飞',
                position: ['四季为农创始人、CEO']
              }
            ]
          },
          {
            date: '10:40-10:50',
            title: '主题演讲 6：数字化平台改造如何让商贸流通管道“变粗变短”',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '陈晟强',
                position: ['易久批联合创始人、COO']
              }
            ]
          },

          {
            date: '10:50-11:00',
            title: ' “聚力自主安全 领跑先进计算”湖南先进计算产业市场应用发展态势',
            type: '',
            host: {},
            tableTalk: false,
            people: [
              {
                name: '杨一艺',
                position: ['新一代自主安全计算产业集群促进中心主任']
              }
            ]
          },

          {
            date: '11:00-11:20',
            title: '圆桌论坛：中国产业互联网平台这轮新的热点和拐点机遇，适合什么样的城市和创业者',
            type: '',
            host: {
              name:'欧阳宇',
              position:['找广 CEO','湖南省电子信息协会产业互联网分会秘书长']
            },
            tableTalk: true,
            people: [
              {
                name: '王兆进',
                position: ['万达信息股份有限公司高级副总裁']
              },
              {
                name: '周文辉',
                position: ['中南大学三高四新战略研究院副院长']
              },
              {
                name: '刘 灿',
                position: ['协同资本数字产业基金(筹) 总经理/合伙人']
              },
              {
                name: '杨文豪',
                position: ['中顺易资本董事、总经理']
              },
              {
                name: '刘双瑞',
                position: ['浣洗创始人兼董事长']
              },
              {
                name: '郭 铖',
                position: ['争渡科技创始人、CEO']
              },
            ]
          },

          // {
          //   date: '16:35-16:50',
          //   title: '《先进节能材料--非晶软磁合金及其在高技术领域的应用前景》',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '姚可夫',
          //       position: ['清华大学材料学院教授']
          //     }
          //   ]
          // },

          // {
          //   date: '16:50-17:05',
          //   title: '《耐热铝合金的高性能化与应用实践》',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '刘  刚',
          //       position: ['西安交通大学金属材料强度国家重点实验室副主任、教授']
          //     }
          //   ]
          // },

          // {
          //   date: '17:05-17:20',
          //   title: '《先进涂层新材料技术创新与产业化实践》',
          //   type: '',
          //   host: {},
          //   tableTalk: false,
          //   people: [
          //     {
          //       name: '曾德长',
          //       position: ['广东正德材料表面科技有限公司董事长']
          //     }
          //   ]
          // },
        ]
      },
      '7cd88218e05f4744aed2e6eaf36d3cbb': { // 湘聚十八洞 振兴新乡村
        name: '湘聚十八洞 振兴新乡村',
        desc: '十八洞村专场设立在湘西州花垣县双龙镇十八洞村。本次专场围绕“互联网+乡村振兴”主题，聚焦于推进脱贫攻坚成果与乡村振兴有效衔接，以及通过互联网的有效助力，在全面推进乡村振兴、加快农业农村现代化上取得更大进展。活动将邀请互联网大咖、扶贫企业嘉宾等参观十八洞村特色景点及支柱产业，与会代表发表主旨演讲，共享思想盛宴，洞见乡村振兴的力量',
        organizer: ['湖南省互联网信息办公室', '湖南省科技厅', '湖南省工业和信息化厅', '湖南湘江新区管委会'],
        undertaker: ['步步高集团'],
        coOrganizer: ['待定'],
        // schedule: [
        //   {
        //     date: '08:00-08:15',
        //     title: '领导、嘉宾梨子寨中心广场集合',
        //     type: '',
        //     people: []
        //   },
        //   {
        //     date: '08:15-09:00',
        //     title: '参观水源地',
        //     type: '',
        //     people: []
        //   },
        //   {
        //     date: '09:00-09:30',
        //     title: '参观十八洞水厂',
        //     type: '',
        //     people: []
        //   },
        // ],
        // contact: {
        //   // name: '周国奇',
        //   // phone: '18711033979'
        // }
      },
      '84fcd5b7c57f43208c05c68f5f1ede6d': { // 专场2： AI驱动 数实融合
        name: 'AI驱动 数实融合',
        poster: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081201353866115.jpg?imageView2/2/w/750',
        desc: "2024互联网岳麓峰会“AI驱动 数实融合”论坛聚焦前沿AI技术，以场景应用为核心，邀请中国科学院院士、中国工业互联网研究院专家和中联重科、西门子、菜鸟、金蝶等AI先进应用代表企业，围绕人工智能大模型的数据标注及在制造、物流、金融、音视频、文旅等多个领域的应用开展深入交流，探索人工智能大模型在多领域的落地应用和发展趋势。",
        theme:'AI驱动 数实融合',
        organizer: ['2024互联网岳麓峰会组委会'],
        undertaker: ['湖南湘江新区行政审批服务局', '中科云谷科技有限公司','湖南金蝶软件科技有限公司','中国工业互联网研究院湖南分院'],
        coOrganizer: ['湖南省人工智能协会'],
        zdOrganizer:['湖南省工业和信息化厅','湖南省科学技术厅','湖南省数据局'],
        zcOrganizer:['岳麓山实验室','湘江实验室','芙蓉实验室','岳麓山工业创新中心'],
        host: {
          // name: '付璐',
          // position: ['湖南电视台主持人']
        },
        contact: {
          name:'黄翔',
          phone:'18874793633'
        },
        users: [

          {
            name: "姜晓明",
            company: "中国科学院科技创新发展中心党委书记、主任，研究员"
          },
          {
            "name": "方明",
            "company": "科大讯飞副总裁"
          },
          {
            "name": "王宁",
            "company": "华为中国政企昇腾生态发展部CTO"
          },
          {
            "name": "唐岳",
            "company": "楚天科技股份有限公司董事长"
          },
          {
            name: "陈晓红",
            company: "中国工程院院士、湖南工商大学党委书记、湘江实验室主任"
          },
          {
            name: "柏连阳",
            company: "中国工程院院士、湖南省农业科学院党委书记"
          },
          {
            name: "陈翔",
            company: "中南大学常务副校长、中南大学湘雅医学院院长、芙蓉实验室主任"
          },
          {
            "name": "李肯立",
            "company": "湖南大学校党委常委、副校长，教授"
          },
        ],
        schedule: [
          {
            date: '09:00-09:15',
            title: '领导讲话',
            host: {},
            tableTalk: false,
            type: '',
            people: []
          },
          {
            date: '09:15-09:25',
            title: '湖南省数据标注产业联盟暨首批数据标注基地授牌仪式',
            host: {},
            tableTalk: false,
            type: '',
            people: [

            ]
          },
          {
            date: '09:25-09:30',
            title: '国家智能网联汽车质量检验检测中心(湖南)揭牌仪式',
            host: {},
            tableTalk: false,
            type: '',
            people: []
          },
          {
            date: '09:30-09:50',
            title: '主旨演讲：生成式人工智能与数字化转型：关键技术与发展趋势',
            host: {},
            tableTalk: false,
            type: '',
            people: [
              {
                name: '谭建荣',
                position: ['中国工程院院士','浙江大学求是特聘教授']
              }
            ]
          },

          {
            date: '09:55-10:10',
            title: '主题演讲 1：人工智能赋能新型工业化',
            host: {},
            tableTalk: false,
            type: '',
            people: [
              {
                name: '张晓彤',
                position: ['中国工业互联网研究院总工程师']
              }
            ]
          },
          {
            date: '10:10-10:25',
            title: '主题演讲 2：人工智能在先进制造的应用',
            host: {},
            tableTalk: false,
            type: '',
            people: [
              {
                name: '付 玲',
                position: ['中联重科副总裁','全国重点实验室主任']
              }
            ]
          },
          {
            date: '10:25-10:40',
            title: '主题演讲 3：AI 赋能产业焕新，打造数字经济新未来',
            host: {},
            tableTalk: false,
            type: '',
            people: [
              {
                name: '赵燕锡',
                position: ['金蝶软件(中国)有限公司执行副总裁','大型企业事业部总裁']
              }
            ]
          },
          {
            date: '10:40-10:55',
            title: '主题演讲 4：智能制造时代的合作共赢：探索商业平台与伙伴的协同效应',
            host: {
            },
            tableTalk: false,
            type: '',
            people: [
              { name: '秦 成', position: ['西门子有限公司副总裁','Xcelerator 平台业务总经理'] },
            ]
          },
          {
            date: '10:55-11:05',
            title: '数实融合应用场景及解决方案发布',
            host: {
            },
            tableTalk: false,
            type: '',
            people: [
              { name: '',position:['(1) 中科云谷发布针对离散型制造业物流场景的人工智能解决方案—虢彦']},
              { name: '',position:['(2) 金蝶发布人力云智能招聘解决方案—陈继华']},
              { name: '',position:['(3) 菜鸟集团发布无人车创新应用解决方案—邱妍']},
              { name: '',position:['(4) 磐云数据发布音视频场景的人工智能解决方案—彭俊']},
              { name: '',position:['(5) 中国电信基于星辰大模型训推一体机的文旅应用场景发布—张伟平']},
              { name: '',position:['(6) 智慧眼科技发布医疗 AI 大脑平台—胡频']},
            ]
          },
          {
            date: '11:05-11:45',
            title: '圆桌论坛：人工智能在先进制造业的应用探讨',
            host: {
              name:'曾 光',
              position:['中科云谷 CEO']
            },
            tableTalk: true,
            type: '',
            people:[
              {name:'高 岩',position:['微软(中国)有限公司高科技制造行业首席顾问']},
              {name:'邴志强',position:['亚马逊云科技商用市场拓展总经理']},
              {name:'邴金友',position:['腾讯云智能制造行业首席专家']},
              {name:'王 丰',position:['华为云计算技术有限公司首席战略官']},
              {name:'高 峰',position:['北京神州光大科技有限公司总裁']},
            ]
          }
        ],
      },
      '15624e51d2524c09967ed8f08895946d': { // 万宝区块链论坛
        name: '构筑创新高地&智汇文创高峰',
        poster: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104151554173070557.png',
        desc: '',
        organizer: ['岳麓峰会组委会'],
        undertaker: ['中国(长沙)马栏山视频文创产业园'],
        coOrganizer: ['湖南省马栏山计算媒体研究院','湖南省人工智能协会智慧文创专委会','湖南省大数据产业促进会','中国电信股份有限公司','华为马栏山音视频创新中心','湖南快乐阳光互动娱乐传媒有限公司','5G高新视频多场景应用国家广电总局重点实验室','长沙云上栏山科技有限公司','湖南天河文链科技有限公司','长沙HTC威爱信息科技有限公司','长沙千博信息技术有限公司'],
        host: {
          name: '待定',
          position: ['']
        },
        // schedule: [
        //   {
        //     date: '13:30-14:00',
        //     title: '会议签到、嘉宾入场、暖场视频',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //     ]
        //   },
        //   {
        //     date: '14:00-14:05',
        //     title: '主持人介绍与会嘉宾',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //     ]
        //   },
        //   {
        //     date: '',
        //     title: '致欢迎辞',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'陈 澎',
        //         position:['长沙市委宣传部部长']
        //       }
        //     ]
        //   },
        //   {
        //     date: '14:05-14:10',
        //     title: '宣布开幕并讲话',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'邹犇淼',
        //         position:['开福区区委副书记、马栏山(长沙)视频文创园党工委书记']
        //       }
        //     ]
        //   },
        //   {
        //     date: '14:10-14:25',
        //     title: '主旨演讲1：<br/>《马栏山视频产业云》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'周苏岳',
        //         position:['马栏山(长沙)视频文创园首席专家']
        //       }
        //     ]
        //   },
        //   {
        //     date: '14:25-14:40',
        //     title: '主旨演讲2：<br/>《数字人工业化流程》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'鲁 维',
        //         position:['华为战略预备队资深咨询顾问']
        //       }
        //     ]
        //   },
        //   {
        //     date: '14:40-14:55',
        //     title: '主旨演讲3：<br />《沉浸式内容的虚拟制作》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'王学哲',
        //         position:['5G高新视频多场景应用国家广电总局重点实验室产品总监']
        //       }
        //     ]
        //   },
        //   {
        //     date: '14:55-15:10',
        //     title: '成果首发：<br/>千博手语生成SaaS服务发布仪式暨华为马栏山音视频中心与千博信息互联网手语合作启动仪式',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'王菊生',
        //         position:['华为马栏山音视频','创新中心主任']
        //       }
        //     ]
        //   },
        //   {
        //     date: '15:10-15:20',
        //     title: '入驻首发：<br/>马栏山(长沙)视频文创园与湖南省六大省级协会入驻签约',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'周 斌',
        //         position:['湖南省人工智能协会秘书长']
        //       }
        //     ]
        //   },
        //   {
        //     date: '15:20-15:35',
        //     title: '主旨演讲4：<br/>《智能化融合媒体计算框架》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'高春鸣',
        //         position:['湖南省马栏山计算媒体研究院院长']
        //       }
        //     ]
        //   },
        //   {
        //     date: '15:35-15:50',
        //     title: '主旨演讲5：<br/>《多模态大模型计算与数字文创应用》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'王金桥',
        //         position:['中国科学院员','自动化研究所研究']
        //       }
        //     ]
        //   },
        //   {
        //     date: '15:50-16:05',
        //     title: '主旨演讲6: <br/>《视频编解码优化与产业实现》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'张 昊',
        //         position:['中南大学计算机学院教授']
        //       }
        //     ]
        //   },
        //   {
        //     date: '16:05-16:20',
        //     title: '主旨演讲7：<br/>《虚幻引擎：数字孪生制造》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'褚 达',
        //         position:['Epic Games China市场部经理']
        //       }
        //     ]
        //   },
        //   {
        //     date: '16:20-16:35',
        //     title: '主旨演讲8：<br/>《NVIDIA Omniverse：艺术与科技的交融》',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'何 展',
        //         position:['NVIDIA中国区Omniverse业务总监']
        //       }
        //     ]
        //   },
        //   {
        //     date: '16:35-17:15',
        //     title: '圆桌论坛',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: [
        //       {
        //         name:'',
        //         position:['马栏山(长沙)视频文创园、中科院自动化所、湖南大学、湖南师大、华为马栏山音视频创新中心、芒果TV、NVIDIA中国区、EPIC Game 中国 ']
        //       }
        //     ]
        //   },
        //   {
        //     date: '17:15',
        //     title: '会议结束：嘉宾合影，观众离场',
        //     host: {},
        //     tableTalk: false,
        //     type: '',
        //     people: []
        //   },
        // ],
        // contact: {

        // },
        // noSign: true
      },
      '3852930159c940479caa5b70d659bacd': { // 创意新高地 数字新世界
        name: '创意新高地 数字新世界',
        poster: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104160024475389930.png',
        desc: '<p>2023年岳麓峰会“创意新高地 数字新世界”专场活动，由中共长沙市委宣传部、长沙高新区管委会主办，湖南草花互动科技股份公司承办，长沙市移动互联网产业联盟、长沙市媒体艺术发展中心、长沙市文化创意产业协会、混沌大学长沙分社协办，围绕数字文化创意产业发展主题，邀请全国数字文创行业产业的核心企业创始人及影响力人物齐聚一堂，并特邀来自腾讯、中手游、盛趣、热云数据的四位核心大咖，现场带来四场主旨演讲，聚焦数字文化产业发展新趋势、中国文化全球化出海新思路，分享最新资讯与宝贵经验，并以圆桌论坛高峰探讨“数字+”“文化+”未来产业发展的新模式和新业态。</P>',
        organizer: ['中共长沙市委宣传部', '长沙高新区管理委员会'],
        undertaker: ['湖南草花互动科技股份公司'],
        coOrganizer: ['长沙市5G应用产业链推进办公室', '长沙市5G产业促进会', '长沙市媒体艺术发展中心', '长沙市文化创意产业协会', '混沌大学长沙分社'],
        host: {
          name: '周庆年',
          position: ['长沙高新区党工委书记']
        },
        // schedule: [
        //   {
        //     date: '14:35-14:40',
        //     title: '发布仪式',
        //     type: '',
        //     host: {},
        //     tableTalk: false,
        //     people: []
        //   },
        //   {
        //     date: '14:40-15:00',
        //     title: '签约仪式',
        //     type: '',
        //     host: {},
        //     tableTalk: false,
        //     people: []
        //   },
        //   {
        //     date: '15:00-16:30',
        //     title: '主旨演讲',
        //     type: '',
        //     host: {},
        //     tableTalk: false,
        //     people: [
        //       {
        //         name: '陈  刚',
        //         position: ['长沙市委常委、宣传部部长']
        //       }
        //     ]
        //   },
        //   {
        //     date: '11:25-12:00',
        //     title: '《数字文化创意产业发展新趋势和新思路》',
        //     type: '圆桌论坛',
        //     host: {
        //       name: '戴建清',
        //       position: ['草花互动董事长']
        //     },
        //     tableTalk: true,
        //     people: [
        //       {
        //         name: '黄康',
        //         position: ['金山云游戏云事业部副总经理']
        //       },
        //       {
        //         name: '吴西',
        //         position: ['游族网络副总裁']
        //       },
        //       {
        //         name: '邹辉',
        //         position: ['中南天使董事长']
        //       },
        //       {
        //         name: '罗沐',
        //         position: ['漫联卡通总裁']
        //       },
        //       {
        //         name: '严新',
        //         position: ['湖南省网络作家协会副主席']
        //       }
        //     ]
        //   }
        // ],
      },
      'pushed': {
        name: '岳麓之夜—湖湘群英荟',
        poster: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104151431387006567.jpg',
        desc: '<p>“岳麓之夜”系由湖湘汇主办策划、聚焦岳麓峰会进行思想碰撞、资源整合的交流平台。活动特邀领导、业界大咖、知名专家等人士，共同就互联网、高科技等产业发展动向和前景进行交流，探讨优质产业项目、赋能产业转型升级，助力湖南“三高四新”战略实施。</p>',
        organizer: ['岳麓峰会组委会'],
        undertaker: ['湖湘汇', '拓维信息系统股份有限公司', '谢子龙影像艺术馆', '长沙麓谷高新创投'],
        coOrganizer: ['长沙市移动互联网及应用软件产业链推进办公室', '长沙市移动互联网产业联盟'],
        address: '谢子龙影像艺术馆1楼多功能厅',
        schedule: [],
      }
    },
    groupArray: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_GROUP(state, group) {
    state.group = group
  },
  SET_GROUPARRAY(state, list) {
    state.groupArray = list
  }
}
export default {
  namespaced: true,
  state,
  mutations
}

