import Vue from 'vue'
import VueRouter from 'vue-router'
import subRouter from "./subRouter"
import http from '../utils/http'
import baseURL from '../utils/baseURL/baseURL'
import store from '../store/index'

Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push 
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

let routes = []

routes = routes.concat(subRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
let hasMicename = false
let hasThemeInfo = false
let miceName = ''
// let hasThemeInfo = false
router.beforeEach(async(to, from, next) => {

  if ((!hasMicename || !hasThemeInfo) && (to.params.miceLink || to.params.miceId)) {
    console.log(to.params.miceId)
    let req = ''
    let res = ''
    if (to.params.miceLink) {
      req = baseURL.index.getMiceInfo() + '?miceLink=' + to.params.miceLink
      res = await http.get(req)
    } else if (to.params.miceId) {
      req = baseURL.index.getMiceInfoById() + to.params.miceId
      res = await http.post(req)
    }

    if (res) {
      miceName = res ? res.miceName : ''
      store.commit('miceInfo/SET_MICE', res)
      hasMicename = true
      http.get(baseURL.index.getThemeInfo() + '/' + to.params.miceLink || '').then(res => {
        store.commit('themeInfo/SET_THEME', res)
        hasThemeInfo = true
        subRouter[0].meta.title = subRouter[0].children[0].meta.title = document.title = miceName
        next()
      })
    } else {
      next('/')
    }
  
  } else {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  }
})

export default router