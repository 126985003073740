const index = {
  getMenuList() {
    // 获取菜单栏
    return "/api/wzMenu/getList";
  },
  getNewsList() {
    // 获取新闻栏
    return "/api/wzNews/getList";
  },
  getNewsPageList() {
    // 分页获取新闻
    return "/api/wzNews/getPageList";
  },
  getPageList() {
    return "/api/wzContact/getList";
  },
  getSelfPageContent() {
    return "/api/wzSelfbuilt/getWzSelfbuiltByLinkName";
  },
  getVideoList() {
    return "/api/wzVideo/getList";
  },
  getGroupList() {
    return "/api/wzGroup/getList";
  },
  getShareInfo() {
    // 微信分享配置
    return "/api/backstageMice/getBackstageMice";
  },
  getSignInfo() {
    // 获取注册配置
    return "/api/backstageFormlabel/info/miceLink";
  },
  sendCode() {
    // 发送验证码
    return "/api/sms/send";
  },
  uploadImg() {
    // 上传图片获取url
    return "/api/upload/uploadImg";
  },
  signUp() {
    // 报名注册
    return "/api/backstageApply/enroll";
  },
  searchByPhone() {
    // 手机号查询电子票
    return "/api/backstageApply/getByPhone";
  },
  searchByOpenid() {
    // openid查询电子票
    return "/api/ylfhBinding/getInfoByOpenId";
  },
  getMiceInfo() {
    // 根据miceLink获取会议信息
    return "/api/backstageMice/getBackstageMice";
  },
  refreshCode() {
    // 刷新二维码
    return "/api/backstageApply/updateCodeById";
  },
  getSchedule() {
    // 获取分会场日程
    return "/api/wzSchedule/getListByGroupId";
  },
  getThemeInfo() {
    // 获取主题信息
    return "/api/wzModule/info";
  },
  signUpModify() {
    return "/api/backstageApply/update";
  },
  getBase64() {
    return "/api/imagemanage/getBase64ImgByUrl";
  },
  getEnterprise() {
    return "/api/ylfhCompany/getPageList";
  },
  recordVisit() {
    return "/api/wzVisitStatistics/add";
  },
  addCompany() {
    return "/api/ylfhCompany/add";
  },
  updateCompany() {
    return "/api/ylfhCompany/update";
  },
  getCompanyList() {
    return "/api/ylfhCompany/getList";
  },
  getCompanyList() {
    return "/api/ylfhCompany/getList";
  },
  openIdExist() {
    return `/api/ylfhBinding/openIdExist`;
  },
  loginByCode() {
    return `/api/ylfhBinding/verification`;
  },
  // 是否报名
  getAuditStatus() {
    return `/api/backstageApply/getAuditStatus`;
  },
  sendTemplate() {
    return `/api/wx/sendTemplate`;
  },
  getSeatByName() {
    return "/api/ylfhSeat/getSeats";
  },
  getDakaList() {
    return "/api/ylfhDk/getList";
  },
  // 自建页面访问量
  getWzVisit() {
    return "/api/wzSelfbuilt/visit";
  },
  getrefreshById() {
    return "/api/backstageApply/info";
  },
  // 点击量
  clickNum() {
    return "/api/wzVisitStatistics/clickNum";
  },
  // 会议详情
  getMiceInfoById() {
    return "/api/backstageMice/detail/";
  },
  // 确认报名
  confirmSignup() {
    return "/api/backstageApply/add";
  },
  getPdfById() {
    return "/api/backstageDoc/getById";
  }
};
export default index;
