// import wx from '../../wx'
const weixin = {
    getSignInfo() { // 获取签名
        return '/api/wx/signInfo'
    },
    getWxCode(url){
        return 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_WX_APPID + '&redirect_uri=' + url +'&response_type=code&scope=snsapi_userinfo#wechat_redirect';
    },
    userSign() {
        return '/api/wx/getUserInfoBycode'
    }
}

export default weixin