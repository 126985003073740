const getDefaultState = () => {
  return {
    type: '2',
    themeColor: '#5094ff', // 主题颜色
    webType: '', 
    contactWordColor: '#000', // 
    scheduleBgColor: '#fff', // 日程背景颜色
    bgUrl: '',
    scheduleBgColorS: '#5094ff50', // 日程头部背景色
    personWordColor: '#5094ff', // 嘉宾字体颜色
    scheduleModule: '', // 日程模板类型
    contactType: 1 // 导航栏类型
  }
}
const state = getDefaultState()

const mutations = {
  SET_THEME: (state, info) => {
    for (const key in state) {
      if (info && info[key] !== '') {
        state[key] = info[key]
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}